import axios from 'axios';
import {
  removeItemInLocalStorage,
  getItemInLocalStorage,
} from '../helpers/localStorageService';
import { AUTH_KEY } from '../constants/authConstant';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
});

axiosInstance.interceptors.request.use((request) => {
  const authData = getItemInLocalStorage(AUTH_KEY);

  if (authData) {
    request.headers['Authorization'] = JSON.parse(authData);
  }
  if (!request.headers['Content-Type']) {
    request.headers['Content-Type'] = 'application/json';
  }
  return request;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const { response } = error;
    if (response.status === 401) {
      removeItemInLocalStorage(AUTH_KEY);
    }
    return Promise.reject(response);
  }
);

const post = async (path, data, headers = {}) => {
  return axiosInstance.post(path, data, headers);
};

const update = async (path, data) => {
  return axiosInstance.put(path, data);
};

const deleteR = async (path, data) => {
  return axiosInstance.delete(path, data);
};

const get = async (path, queryParams = '') => {
  return axiosInstance.get(`${path}${queryParams ? '?' + queryParams : ''}`);
};

export { axiosInstance, post, update, deleteR, get };

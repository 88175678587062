import { lazy } from 'react';

const HomePage = lazy(() => import('../HomePage/Hompage'));
const About = lazy(() => import('../About'));
const Contact = lazy(() => import('../Contact'));
const Login = lazy(() => import('../Login'));
const Media = lazy(() => import('../Media'));
const Industry = lazy(() => import('../Industry'));
const IndustryListServices = lazy(() =>
  import('../Industry/IndustryListServices')
);
const IndustryItemDetail = lazy(() => import('../Industry/IndustryItemDetail'));
const Policies = lazy(() => import('../Policies'));
const Catalogue = lazy(() => import('../Catalogues'));
const publicRoutes = [
  {
    path: '/',
    exact: true,
    name: 'home',
    component: HomePage,
  },
  {
    path: '/home',
    exact: true,
    name: 'home',
    component: HomePage,
  },
  {
    path: '/policies',
    exact: true,
    name: 'policies',
    component: Policies,
  },
  {
    path: '/about',
    exact: true,
    name: 'about',
    component: About,
  },
  {
    path: '/catalogues',
    exact: true,
    name: 'about',
    component: Catalogue,
  },
  { path: '/contact', exact: true, name: 'home', component: Contact },
  { path: '/login', name: 'LoginPage', component: Login },
  { path: '/media', exact: true, name: 'mediaPage', component: Media },
  {
    path: '/industries/:industry',
    exact: true,
    name: 'industries page',
    component: Industry,
  },
  {
    path: '/industries/:industry/:listServices',
    exact: true,
    name: 'IndustryListServices',
    component: IndustryListServices,
  },
  {
    path: '/industries/:industry/:listServices/:detailItem',
    name: 'industries page',
    component: IndustryItemDetail,
  },
];
export default publicRoutes;

import {
  // post, update, deleteR,
  get,
} from './baseApi';

// export async function createApi(data) {
//   return post('/api/secItems/create', { data: data });
// }
// export async function updateApi(data) {
//   return update('/api/secItems/update', data);
// }
// export async function deleteApi(data) {
//   return deleteR('/api/secItems/delete', { data: data });
// }
export async function getMenuItemApi(data) {
  return get('/api/menuitems', data);
}

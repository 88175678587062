import React, { createContext, useState, useEffect } from 'react';
import { createApi, updateApi, deleteApi, getSecApi } from '../api/SectionApi';
import useRequest from '../hooks/useRequest';
import { toast } from 'react-toastify';

export const SectionContext = createContext({
  modalState: {
    isAdding: false,
    isUpdating: false,
    isConfirm: false,
  },
});

export function SectionContextProvider({ children }) {
  const [modalState, setModalState] = useState({
    isAdding: false,
    isEditting: false,
    isConfirm: false,
  });
  //
  const [currentSec, setCurrentSec] = useState(null);
  const [secId, setSectionId] = useState(0);
  const [secList, setSecList] = useState([]);
  //
  const fetchSecRequest = useRequest({ request: getSecApi });
  const createRequest = useRequest({ request: createApi });
  const updateRequest = useRequest({ request: updateApi });
  const deleteRequest = useRequest({ request: deleteApi });

  const handleModalState = (modal, value) => {
    setModalState({
      ...modalState,
      [modal]: value,
    });
  };

  async function fetchSec() {
    try {
      const data = await fetchSecRequest.execute();
      setSecList(data);
    } catch (error) {
      toast.error(error);
    }
  }
  useEffect(() => {
    fetchSec();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secId]);

  async function createSec(data) {
    try {
      await createRequest.execute(data);
      handleModalState('isAdding', false);
      toast.success('create new section success');
    } catch (err) {
      toast.error('Create new section unsuccessful');
    }
  }

  async function updateSec(data) {
    try {
      await updateRequest.execute({ data: data });
      handleModalState('isUpdating', false);
      handleModalState('isConfirm', false);
      setCurrentSec(null);
      setSectionId(0);
      toast.success('The section has been updated');
    } catch (err) {
      toast.error('Updated unsuccessfully');
    }
  }
  async function deletePage(data) {
    try {
      await deleteRequest.execute(data);
      handleModalState('isConfirm', false);
      setCurrentSec(null);
      setSectionId(0);
      toast.success('The page has been deleted');
    } catch (error) {
      toast.error('Deleted unsuccessfully');
    }
  }

  const value = {
    handleModalState: React.useCallback(handleModalState, [
      modalState,
      setModalState,
    ]),
    createSec,
    modalState,
    updateSec,
    updateRequest,
    deletePage,
    currentSec,
    setCurrentSec,
    secId,
    setSectionId,
    secList,
  };
  return (
    <SectionContext.Provider value={value}>{children}</SectionContext.Provider>
  );
}

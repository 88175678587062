import React, { memo, lazy } from 'react';
import { Switch } from 'react-router-dom';
import PublicPageFn from '../../components/PublicPageFn';
import PublicPages from '../PublicPages';
import PrivatePageFn from '../../components/PrivatePageFn';
import PrivatePages from '../PrivatePages';
import { PageContextProvider } from '../../contextStore/PageState';
import { SectionContextProvider } from '../../contextStore/SectionState';
import { ItemContextProvider } from '../../contextStore/SectionItemState';

const Login = lazy(() => import('../Login'));

function MainPages() {
  return (
    <PageContextProvider>
      <SectionContextProvider>
        <ItemContextProvider>
          <Switch>
            <PrivatePageFn
              path='/auth'
              name='PrivatePages'
              component={PrivatePages}
            />
            <PublicPageFn
              path='/'
              restricted={false}
              name='Public'
              component={PublicPages}
            />
            <PublicPageFn
              path='/login'
              restricted={false}
              name='Auth'
              component={Login}
            />
          </Switch>
        </ItemContextProvider>
      </SectionContextProvider>
    </PageContextProvider>
  );
}

export default memo(MainPages);

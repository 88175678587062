import React, { createContext, useState, useEffect } from 'react';
import { getMenuItemApi } from '../api/MenuItems';
import useRequest from '../hooks/useRequest';

export const MenuState = createContext(null);

export const MenuProvider = React.memo(({ children }) => {
  const [menuItemList, setMenuItemList] = useState([]);

  const fetchItemMenu = useRequest({ request: getMenuItemApi });

  async function fetchMenuItemFn() {
    try {
      const data = await fetchItemMenu.execute();

      setMenuItemList(data);
    } catch (e) {
      console.log('🚀 ~ file: MenuState.js ~ line 16 ~ fetchItemMenu ~ e', e);
    }
  }
  useEffect(() => {
    fetchMenuItemFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const MenuValue = {
    menuItemList,
  };

  return <MenuState.Provider value={MenuValue}>{children}</MenuState.Provider>;
});

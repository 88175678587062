import React, { createContext, useState, useEffect } from 'react';
import {
  createPageApi,
  updatePageApi,
  deletePageApi,
  getPageApi,
} from '../api/PagesApi';
import useRequest from '../hooks/useRequest';
import { toast } from 'react-toastify';

export const PageContext = createContext({
  modalState: {
    isAdding: false,
    isUpdating: false,
    isConfirm: false,
  },
});

export function PageContextProvider({ children }) {
  const [modalState, setModalState] = useState({
    isAdding: false,
    isEditting: false,
  });
  const [pageList, setPageList] = useState([]);
  const fetchPageListRequest = useRequest({ request: getPageApi });
  const createPageRequest = useRequest({ request: createPageApi });
  const updateRequest = useRequest({ request: updatePageApi });
  const deleteRequest = useRequest({ request: deletePageApi });
  const [currentPage, setCurrentPage] = useState(null);
  const [pageId, setPageId] = useState(0);

  const handleModalState = (modal, value) => {
    setModalState({
      ...modalState,
      [modal]: value,
    });
  };
  async function fetchPageList() {
    try {
      const data = await fetchPageListRequest.execute();
      setPageList(data);
    } catch (error) {
      toast.error(error);
    }
  }
  useEffect(() => {
    fetchPageList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId]);

  async function createPage(data) {
    try {
      await createPageRequest.execute(data);
      handleModalState('isAdding', false);
      toast.success('create new page success');
    } catch (err) {
      toast.error(err.data.msg);
    }
  }

  async function updatePage(data) {
    try {
      await updateRequest.execute({ data: data });
      handleModalState('isUpdating', false);
      handleModalState('isConfirm', false);
      setCurrentPage(null);
      setPageId(0);
      toast.success('The page has been updated');
    } catch (err) {
      toast.error(err.data.msg);
    }
  }
  async function deletePage(data) {
    try {
      await deleteRequest.execute(data);
      handleModalState('isConfirm', false);
      setCurrentPage(null);
      setPageId(0);
      toast.success('The page has been deleted');
    } catch (error) {
      toast.error('Deleted unsuccessfully');
    }
  }

  const value = {
    handleModalState: React.useCallback(handleModalState, [
      modalState,
      setModalState,
    ]),
    createPage,
    modalState,
    updatePage,
    updateRequest,
    deletePage,
    currentPage,
    setCurrentPage,
    pageId,
    setPageId,
    pageList,
    fetchPageList,
  };
  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
}

import { memo, Suspense, useEffect } from 'react';
import { HashRouter } from 'react-router-dom';
import { Menu, Footer } from './containers/commons';
import MainPages from './containers/MainPages';
import { AuthProvider } from './contextStore/AuthState';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/style.scss';
import ScrollToTop from './containers/commons/ScrollToTop';
import tawkTo from 'tawkto-react';
import { MenuProvider } from './contextStore/MenuState';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  const tawkToPropertyId = '5669a4c495ec1b9f3eb17590';

  // Direct Chat Link
  // https://tawk.to/chat/tawkToPropertyId/tawkToKey

  const tawkToKey = 'default';

  useEffect(() => {
    tawkTo(tawkToPropertyId, tawkToKey);
  }, []);
  return (
    <>
      <HashRouter>
        <Suspense fallback={<div />}>
          <AuthProvider>
            <ScrollToTop>
              <MenuProvider>
                <Menu />
              </MenuProvider>
              <main>
                <MainPages />
              </main>
              <Footer />
            </ScrollToTop>
          </AuthProvider>
        </Suspense>
      </HashRouter>
      <ToastContainer />
    </>
  );
}

export default memo(App);

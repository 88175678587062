import React, { memo } from 'react';
import FooterC from '../../components/commons/FooterC';
import { toast } from 'react-toastify';
import { createNewSub } from '../../api/subApi';

function Footer() {
  async function submitFn(data) {
    try {
      const res = await createNewSub({ data: data });
      toast.success(res.msg);
    } catch (error) {
      toast.error(error.data.msg);
    }
  }

  return <FooterC submitFn={submitFn} />;
}

export default memo(Footer);

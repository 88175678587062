import React, { createContext, useState } from 'react';

export const ContactQuestContext = createContext(null);

export const ContactQuestProvider = React.memo(({ children }) => {
  const [questCatelogue, setQuestCatelogue] = useState(null);

  const questValue = {
    questCatelogue,
    setQuestCatelogue,
  };

  return (
    <ContactQuestContext.Provider value={questValue}>
      {children}
    </ContactQuestContext.Provider>
  );
});

function getItemInLocalStorage(key) {
  return localStorage.getItem(key);
}

function removeItemInLocalStorage(key) {
  return localStorage.removeItem(key);
}

function setItemInLocalStorage(key, value) {
  return localStorage.setItem(key, value);
}
export { getItemInLocalStorage, removeItemInLocalStorage, setItemInLocalStorage };

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import publicRoutes from './publicRoutes';
import { ContactQuestProvider } from '../../contextStore/ContactQuestState';

function PublicPages() {
  return (
    <ContactQuestProvider>
      <Switch>
        {publicRoutes.map((route, idx) => {
          return (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              name={route.name}
              component={route.component}
            />
          );
        })}
        <Route path='*' component={() => '404 NOT FOUND'} />
      </Switch>
    </ContactQuestProvider>
  );
}

export default PublicPages;

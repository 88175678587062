import React from 'react';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import facebookLogo from '../../images/facebookLogo.png';
import youtubeLogo from '../../images/youtubeLogo.png';
import linkedinLogo from '../../images/linkedinLogo.png';
import googleplusLogo from '../../images/googleplusLogo.png';
import rightArrow from '../../images/rightArrow.png';
import { HashLink as Link } from 'react-router-hash-link';

function FooterC({ submitFn }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function onSubmit(data) {
    const newdata = {
      ...data,
      date: new dayjs().format('DD/MM/YYYY'),
    };
    submitFn(newdata);
  }
  return (
    <footer>
      <div className='container'>
        <ul className='footer_wrapper no-bullet row'>
          <li className='footerAddress col-lg-3 col-md-6 col-sm-12 col-12'>
            <h4>HEAD OFFICE</h4>
            <address className='mt-3'>
              3 Greengage Rd., New LowellON, <br /> Canada L0M 1N0
            </address>
            <div className='contact_inform mt-3'>
              <div className='d-flex justify-content-between'>
                <p className='text-capitalize'>Toll Free:</p>
                <span className='text-danger'>+1 (888) 434-0253</span>
              </div>
              <div className='d-flex justify-content-between'>
                <p className='text-capitalize  '>Telephone:</p>
                <span className='text-danger'>+1 (705) 424-9960</span>
              </div>
              <div className='d-flex justify-content-between'>
                <p className='text-capitalize'>FAX:</p>
                <span className='text-danger'>+1 (705) 435-6419</span>
              </div>
              <div className='d-flex justify-content-between'>
                <p className='text-capitalize'>E-mail:</p>
                <a href='mailto:sales@provix.net'>sales@provix.net</a>
              </div>
            </div>
          </li>

          <li className='saleOffice  col-lg-3 col-md-6 col-sm-12 col-12'>
            <h4>SALES OFFICE</h4>
            <address className='mt-3'>
              1191 Lansing Ave. <br /> Unit 1, SudburyON, Canada P3A 4C4
            </address>
            <div className='contact_inform mt-3'>
              <div className='d-flex  justify-content-between'>
                <p className='text-capitalize'>Telephone:</p>
                <span className='text-danger'>+1 (705) 885-9994</span>
              </div>
              <div className='d-flex justify-content-between'>
                <p className='text-capitalize'>E-mail:</p>
                <a href='mailto:sales@provix.net'>sales@provix.net</a>
              </div>
            </div>
          </li>

          <li className='copyAndSub  col-lg-3 col-md-6 col-sm-12 col-12'>
            <h4>COPYRIGHT</h4>
            <div className='copyright__wrapper mt-3'>
              <p className='copyright__content'>
                Pending: <br /> ISO 9001:2008 Certification
              </p>
              <Link to='/policies'>© | POLICIES</Link>
              <br />
              <ul className='no-bullet d-flex mt-5 social_list'>
                <li>
                  <a
                    href='https://www.facebook.com/PROVIX.net'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      src={facebookLogo}
                      alt='Facebook'
                      width='30'
                      height='30'
                    />
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.youtube.com/user/ProvixInc/feed'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      src={youtubeLogo}
                      alt='Youtube'
                      width='30'
                      height='30'
                    />
                  </a>
                </li>
                <li>
                  <a
                    href='http://www.linkedin.com/company/provix'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      src={linkedinLogo}
                      alt='Linked in'
                      width='30'
                      height='30'
                    />
                  </a>
                </li>
                <li>
                  <a
                    href='https://plus.google.com/+ProvixNet/about'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      src={googleplusLogo}
                      alt='Google Plus'
                      width='30'
                      height='30'
                    />
                  </a>
                </li>
              </ul>
            </div>
          </li>

          <li className='footerSubscribe  col-lg-3 col-md-6 col-sm-12 col-12'>
            <h4>Newsletter</h4>
            <form
              id='form_sub'
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              className='mt-3'
            >
              <div className='form-group'>
                <input
                  id='email'
                  className='footer_sub_form'
                  {...register('email', {
                    required: 'Please input your email',
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Entered value does not match email format',
                    },
                  })}
                  type='email'
                />
                {errors.email && (
                  <span role='alert' className='text-danger'>
                    {errors.email.message}
                  </span>
                )}
              </div>
              <button
                type='submit'
                className='subscribeButton'
                rel='noreferrer'
              >
                Subscribe
                <img src={rightArrow} alt='GO' width='18' height='18' />
              </button>
            </form>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default FooterC;

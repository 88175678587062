import React, { memo } from 'react';

function Section({ title, children, id, newClass }) {
  return (
    <section className={`${newClass ? newClass : ''} section_page`} id={id}>
      <div className='section_wrapper'>
        <div className='container'>
          {title && <h1 className='section_title text-uppercase'>{title}</h1>}
          {children}
        </div>
      </div>
    </section>
  );
}

export default memo(Section);

import React, { createContext, useState, useEffect } from 'react';
import { axiosInstance } from '../api/baseApi';
import axios from 'axios';
import {
  setItemInLocalStorage,
  getItemInLocalStorage,
  removeItemInLocalStorage,
} from '../helpers/localStorageService';
import { AUTH_KEY } from '../constants/authConstant';
import { toast } from 'react-toastify';

export const AuthState = createContext(null);

export const AuthProvider = React.memo(({ children }) => {
  const [errorAuth, setErrorAuth] = useState('');
  const [isLogged, setIsLogged] = useState(false);
  const [user, isUserLogin] = useState('');
  function refreshPage() {
    window.location.reload();
  }
  // First login
  const login = async (data) => {
    try {
      setErrorAuth(null);
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/user/login`,
        {
          data,
        }
      );
      setItemInLocalStorage(AUTH_KEY, JSON.stringify(res.accesstoken));

      window.location.href = '/#/auth/adminhome';
      refreshPage();
    } catch (err) {
      toast.error(err.data.msg);
      // setErrorAuth(err.data.msg);
      // fixed conflig
    }
  };
  // Get user after login
  useEffect(() => {
    const tokenData = async () => {
      if (getItemInLocalStorage(AUTH_KEY)) {
        const token = await JSON.parse(getItemInLocalStorage(AUTH_KEY));

        if (token) {
          setIsLogged(true);
          const user = await getUserFn(token);

          isUserLogin(user.data.name);
          setIsLogged(true);
        }
      }
    };
    tokenData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getItemInLocalStorage(AUTH_KEY)]);

  const getUserFn = async (tokenAuthen) => {
    try {
      const user = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/infor`,
        { headers: { Authorization: tokenAuthen } }
      );
      return user;
    } catch (error) {
      logout();
    }
  };
  // End get user

  // Logout
  function logout() {
    setIsLogged(false);
    removeItemInLocalStorage(AUTH_KEY);
    // history.push('/home');
    window.location.href = '/#/home';
    refreshPage();
  }
  //

  const AuthValue = {
    login,
    errorAuth,
    user,
    isLogged,
    logout,
    setIsLogged,
  };

  return <AuthState.Provider value={AuthValue}>{children}</AuthState.Provider>;
});

import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
const EditPages = lazy(() => import('../Edit/EditPages'));
const EditItems = lazy(() => import('../Edit/EditItems/EditItems'));
const EditSections = lazy(() => import('../Edit/EditSections'));
const AdminHome = lazy(() => import('../HomePage/AdminHome'));

function PrivatePages() {
  return (
    <Switch>
      <Route
        path='/auth/adminhome'
        exact
        name='Admin Home'
        component={AdminHome}
      />
      <Route
        path='/auth/editpages'
        exact
        name='Admin Home'
        component={EditPages}
      />
      <Route
        path='/auth/editsections'
        exact
        name='Admin Home'
        component={EditSections}
      />
      <Route
        path='/auth/edititems'
        exact
        name='Admin Home'
        component={EditItems}
      />
    </Switch>
  );
}

export default PrivatePages;

import { post } from './baseApi';

export async function uploadFileApi(files) {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append('file', file, file.name);
  });

  return post('/api/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then((data) => {
    return data;
  });
}

import { post, update, deleteR, get } from './baseApi';

export async function createApi(data) {
  return post('/api/sections/create', data);
}
export async function updateApi(data) {
  return update('/api/sections/update', data);
}
export async function deleteApi(data) {
  return deleteR('/api/sections/delete', { data: data });
}

export async function getSecApi(data) {
  return get('/api/sections', data);
}

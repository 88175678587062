import { post, update, deleteR, get } from './baseApi';

export async function createPageApi(data) {
  return post('/api/allPages/create', data);
}
export async function updatePageApi(data) {
  return update('/api/allPages/update', data);
}
export async function deletePageApi(data) {
  return deleteR('/api/allPages/delete', { data: data });
}
export async function getPageApi(data) {
  return get('/api/allPages', data);
}

import React, { useContext, useState, memo, useEffect } from 'react';
import { MenuState } from '../../contextStore/MenuState';
import {
  Navbar,
  Collapse,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import logo from '../../images/mainLogo.png';
import { AuthState } from '../../contextStore/AuthState';
import { NavLink as RRNavLink } from 'react-router-dom';
function Menu() {
  const { user, isLogged, logout } = useContext(AuthState);

  const [menuList, setMenuList] = useState([]);
  const [isScroll, setIsScroll] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  useEffect(() => {
    function handleScroll(height) {
      if (height > 300) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
    }
    window.addEventListener('scroll', () => handleScroll(window.scrollY));

    return function cleanup() {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScroll]);

  const { menuItemList } = useContext(MenuState);

  useEffect(() => {
    if (isLogged) {
      const newMenu = menuItemList.filter((item) => item.parent === 'admin');
      setMenuList(newMenu);
    } else {
      const newMenu = menuItemList.filter((item) => item.parent === 'default');
      setMenuList(newMenu);
    }
  }, [menuItemList, isLogged, user]);

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
  };

  return (
    <header
      className={`${isScroll === true ? 'fixed' : ''} headerMenu ${
        isOpen ? 'h-100' : ''
      }`}
    >
      <div className='container'>
        <Navbar color='light' light expand='md'>
          <NavbarBrand href={`${isLogged ? '/#/auth/adminhome' : '/#/home'}`}>
            <div className='logo-wrapper'>
              <img src={logo} alt='main logo' />
            </div>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className='mr-auto navbar_list' navbar>
              {menuList &&
                menuList.length > 0 &&
                menuList.map((nav, i) => {
                  return (
                    <React.Fragment key={i}>
                      {isLogged ? (
                        <React.Fragment key={nav.id}>
                          <NavItem>
                            <>
                              {nav.runFn === 'T' ? (
                                <NavLink
                                  className={` auth_menu_link text-uppercase`}
                                  onClick={(e) => handleLogout(e)}
                                >
                                  {nav.title}
                                </NavLink>
                              ) : (
                                <NavLink
                                  className='auth_menu_link text-uppercase'
                                  to={`/${nav.link}`}
                                  tag={RRNavLink}
                                  activeClassName='active'
                                >
                                  {nav.title}
                                </NavLink>
                              )}
                            </>
                          </NavItem>
                        </React.Fragment>
                      ) : (
                        <NavItem key={nav.id}>
                          {nav.outsideLink ? (
                            <NavLink
                              className='text-uppercase'
                              href={nav.outsideLink}
                            >
                              {nav.title}
                            </NavLink>
                          ) : (
                            <NavLink
                              className='text-uppercase'
                              to={`/${nav.link}`}
                              tag={RRNavLink}
                              activeClassName='active'
                              onClick={() => setIsOpen(false)}
                            >
                              {nav.title}
                            </NavLink>
                          )}
                        </NavItem>
                      )}
                    </React.Fragment>
                  );
                })}
              {user && <h2 style={{ color: 'red' }}> Hello {user} </h2>}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </header>
  );
}
export default memo(Menu);

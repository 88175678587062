import React, { createContext, useState, useEffect } from 'react';
import { createApi, updateApi, deleteApi, getItemApi } from '../api/SecItemApi';
import useRequest from '../hooks/useRequest';
import { toast } from 'react-toastify';

export const ItemContext = createContext({
  modalState: {
    isAdding: false,
    isUpdating: false,
    isConfirm: false,
  },
});

export function ItemContextProvider({ children }) {
  const [modalState, setModalState] = useState({
    isAdding: false,
    isEditting: false,
    isConfirm: false,
  });
  const [secItemList, setSecItem] = useState([]);
  const fetchItemRequest = useRequest({ request: getItemApi });
  const createRequest = useRequest({ request: createApi });
  const updateRequest = useRequest({ request: updateApi });
  const deleteRequest = useRequest({ request: deleteApi });
  const [currentItem, setCurrentItem] = useState(null);
  const [itemId, setItemId] = useState(0);

  const handleModalState = (modal, value) => {
    setModalState({
      ...modalState,
      [modal]: value,
    });
  };

  async function fetchItem() {
    try {
      const data = await fetchItemRequest.execute();
      setSecItem(data);
    } catch (error) {
      toast.error(error);
    }
  }

  useEffect(() => {
    fetchItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  async function createItem(data) {
    try {
      await createRequest.execute(data);
      handleModalState('isAdding', false);
      toast.success('create new section success');
    } catch (err) {
      toast.error('Create new section unsuccessful');
    }
  }

  async function updateItem(data) {
    try {
      await updateRequest.execute({ data: data });
      handleModalState('isUpdating', false);
      handleModalState('isConfirm', false);
      setCurrentItem(null);
      setItemId(0);
      toast.success('The section has been updated');
    } catch (err) {
      toast.error('Updated unsuccessfully');
    }
  }
  async function deleteItem(data) {
    try {
      await deleteRequest.execute(data);
      handleModalState('isConfirm', false);
      setCurrentItem(null);
      setItemId(0);
      toast.success('The page has been deleted');
    } catch (error) {
      toast.error('Deleted unsuccessfully');
    }
  }

  const value = {
    handleModalState: React.useCallback(handleModalState, [
      modalState,
      setModalState,
    ]),
    createItem,
    modalState,
    updateItem,
    updateRequest,
    deleteItem,
    currentItem,
    setCurrentItem,
    itemId,
    setItemId,
    secItemList,
  };
  return <ItemContext.Provider value={value}>{children}</ItemContext.Provider>;
}

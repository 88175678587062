import {
  post,
  // get, patch, deleteR
} from './baseApi';

// export async function getSub(offset = 0, limit, keywords = '') {
//   return get(`/customer?offset=${offset}&limit=${limit}&keyword=${keywords}`).then((data) => data.data);
// }

export async function createNewSub(data) {
  return post('/api/sub/create', data);
}

// export async function updateCustomerApi(customerId, data) {
//   return patch(`/customer/${customerId}`, data);
// }
// export async function removeCustomerApi(customerId) {
//   return deleteR(`/customer/${customerId}`);
// }

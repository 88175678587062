import React, { memo } from 'react';

function TitlePage({ children, newClass }) {
  return (
    <div className='title_page'>
      <div className={`${newClass ? newClass : ''} title_page__wrapper`}>
        {children}
      </div>
    </div>
  );
}

export default memo(TitlePage);

import React, { memo } from 'react';
// import { Link } from 'react-router-dom';
import rightArrow from '../../images/rightArrow.png';
import { HashLink as Link } from 'react-router-hash-link';
function ButtonDefault({ outsite_link, btnName, link, newClass }) {
  return (
    <div className='section_btn_wrapper'>
      {outsite_link ? (
        <a href={outsite_link} className='btn btn-default'>
          {btnName}
          <img src={rightArrow} alt='GO' width='25' height='25' />
        </a>
      ) : (
        <Link
          to={link}
          className={`${newClass ? newClass : ''} btn btn-default`}
        >
          {btnName}
          <img src={rightArrow} alt='GO' width='25' height='25' />
        </Link>
      )}
    </div>
  );
}

export default memo(ButtonDefault);
